@import "../../global.scss";

.topbar{
    width: 100%;
    height: 70px;
    background-color:  rgb(255, 255, 255);
    color: $mainColor;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 1s ease;
    overflow: hidden;

    .wrapper{
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left{
            display: flex;
            align-items: center;

            .logo{
                font-size: 42px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;

                @include mobile{
                    font-size: 25px;
                }
            }

        

            .itemcontainer{
                display: flex;
                align-items: center;
                margin-right: 30px;

                @include mobile{
                    display: none;
                }

                .icon{
                    font-size: 18px;
                    margin-right: 5px;
                }

                span{
                    font-size: 15px;
                    font-weight: 500;
                }

                @include seven{
                    .icon{
                        font-size: 12px;
                        margin-right: 2px;
                    }
    
                    span{
                        font-size: 15px;
                        font-weight: 500;
                    }
                }

                @include intro{
                        display: none;
                }
            }

        }

        .right{

            .hamburger{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;


                span{
                    height: 3px;
                    width: 100%;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 2s ease;

                }

            }
        }
    }

    &.active{
        background-color: $mainColor;
        color: white;

        .itemcontainer{
            .icon{
                color: white;
            }
            span{
                color: white;
            }
        }

        .hamburger{
            span{
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}