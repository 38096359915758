@import '../../global.scss';

.intro{
    background-color: white;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .left{
        flex: 0.5;
        overflow: hidden;
        
        @include longscreen{
            margin-top: 15vh;
        }

        @include intro{
            flex: 0;
        }

        .imgContainer{
            width: 520px;
            height: 520px;
            background-color: rgb(29, 68, 197);
            box-shadow: 9px 9px 4px #464444;
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;
            margin-top: 15vh;
            margin-right: 2vh;
            
        

            @include intro{
                display:  none;
                height: 400px;
                width: 400px;
                margin-top: 25vh;
            }

            img{
                height: 800px;
                width: 600px;
                object-fit: none;
                overflow: hidden;
                object-position: 53% -5%;

                @include intro{
                    height: 400px;
                }

                @include mobile{
                    height: 300px;
                }
            }
        }
      
    }
    .right{
        flex: 0.5;
        position: relative;

        @include intro{
            flex: 1;
            align-items: center;
            text-align: center;
        }

        .wrapper{
            width: 80%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include intro{
                padding-left: 100px;
            }

            @include mobile{
                padding-left: 0;
                width: 100%;
                align-items: center;
            }


            h1{
                font-size: 60px;
                margin: 10px 0;

                @include mobile{
                    font-size: 30px;
                }
            }

            h2{
                font-size: 35px;
                @include mobile{
                    font-size: 20px;
                }

            }

            h3{
                font-size: 30px;

                @include mobile{
                    font-size: 20px;
                }

                span{
                    font-size: 30px;
                    color: crimson;

                    @include mobile{
                        font-size: 20px;
                    }
                }

                .ityped-cursor{
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }
        }
        

    }
}