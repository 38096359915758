@import '../../global.scss';


.about{
    background-image: url("about-background.jpg");
    overflow:hidden;
    
    h1{
        font-size: 40px;
        align-items: center;
        text-align: center;
        padding-top: 25px;
        font-weight: 700;

        @include seven{
            font-size: 1.4rem;
            padding-top: 10px;
        }

        @include mobile{
            padding-top: 3px;
            font-size: 1.5rem;
        }
        
    }

    .introduction{
        display: flex;

        //When the screen is not big enough for side by side
        @include seven{
            flex-direction: column;
            align-items: center;
        }

        .about-content{
            flex: 33%;
            
            p{
            font-size: 1.3rem;
            font-weight: 400;
            align-items: center;
            text-align: center;
            width: 600px;
            margin-left: 15vh;
            margin-top: 5rem;
            padding: 50px;
            background-color: white;
            border-radius: 5%;
            
            

            //1300px
            @include intro{
                font-size: 1rem;
                width: 800px;
                margin-left: 10vh;
                margin-left: 0;
            }

            @include seven{
                font-size: 1rem;
                margin-left: 0;
                width: 700px;
                padding-top: 10px;
            }

            @include mobile{
                font-size: 0.65rem;
                width: 300px;
                margin-top: .3rem;
                font-weight: 400;
                padding:15px;
            }
            
                span{
                    font-size: 1.8rem;
                    font-weight: 700;
                    text-decoration: underline;

                    @include intro{
                        font-size: 1.4rem;
                    }

                    @include mobile{
                        font-size: 1rem;
                        width: 300px;
                        margin-left: 0;
                    }
                }


            }
    
        }
        .about-content1{
            flex: 33%;
            
            
            p{
            font-size: 1.3rem;
            font-weight: 400;
            align-items: center;
            text-align: center;
            width: 600px;
            margin-left: 10vh;
            padding-top: 50px;
            overflow: hidden;
            padding: 50px;
            margin-top: 5rem;
            background-color: white;
            border-radius: 5%;

            @include intro{
                font-size: 1rem;
                margin-left: 0;
                width: 800px;
            }

            @include seven{
                font-size: 1rem;
                width: 600px;
                margin-left: 0;
                width: 700px;
                padding-top: 10px;
            }

            @include mobile{
                font-size: 0.65rem;
                width: 300px;
                margin-left: 0;
                margin-top: .5rem;
                padding:15px;

            }
            
                span{
                    font-size: 1.8rem;
                    font-weight: 700;
                    text-decoration: underline;

                    @include intro{
                        font-size: 1.4em;
                        
                    }

                    @include mobile{
                        font-size: 1rem;
                        
                    }
                }

                a{
                    text-decoration: solid;
                    color: black;
                    font-size: 1.7rem;
                    font-weight: 700;
                    padding: 10px;
                    

                    @include intro{
                        font-size: 1.2em;
                    }

                    @include mobile{
                        font-size: 1rem;
                    }
                }

                a:hover{
                    color: rgb(209, 36, 36);
                    cursor: pointer;
                    text-decoration: none;
                    text-shadow: 0 0 1px rgb(209, 36, 36);
                }

        }
    
    }
    .middle{
        border-left: 5px solid rgb(165, 160, 160) ;
        height: 70vh;
        margin-top: 2rem;
        margin: 20px;
        border-radius: 15%;

        @include seven{
            display: none;
        }
    }  
    }

}