
@import '../../global.scss';
.contact{
    background-color: white;
    display: flex;
    

    @include mobile{
        flex-direction: column;
    }


    .main{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        

        h2{
            font-size: 50px;

            @include mobile{
                font-size: 20px;
            }
        }

        form{
            width: 500px;
            text-align: center;
            height: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            @include longscreen{
                height: 30%;
            }
            

            input{
                width: 400px;
                height: 30px;
                font-size: 22px;

                @include intro{
                    font-size: 15px;
                    margin-left: 100px;
                }
                
                @include mobile{
                    width: 200px;
                    height: 30px;
                    font-size: 10px;
                    margin-left: 3px;
                }
            }

            textarea{
                width: 400px;
                height: 300px;
                font-size: 22px;

                @include intro{
                    font-size: 15px;
                    margin-left: 100px;
                }
                
                @include mobile{
                    width: 200px;
                    height: 100px; 
                    font-size: 10px;
                    margin-top: 2px;
                    margin-left: 3px;
                }
                
            }



            button{
                width: 200px;
                height: 50px;
                color:white;
                background-color: rgb(26, 20, 21);
                border: none;
                font-size: 20px;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;

                @include mobile{
                    width: 100px;
                    height: 30px;
                    margin-top: 3px;
                }

                &:focus{
                    outline: none;
                }
            }

            button:hover{
                text-decoration: none;
                text-shadow: 0 0 1px rgb(209, 36, 36);
            }

            span{
                color: green;
            }
        }
    }
}