@import '../../global.scss';

.testimonials{
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @include mobile{
        justify-content: space-around;
    }

    h1{
        font-size: 50px;
        margin-top: 15px;

        @include mobile{
            font-size: 20px;
        }
    }

    .container{
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile{
            flex-direction: column;
            height: 95%;
        }


        .card{
            width: 350px;
            height: 70%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px black;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition: all 1s ease;

            @include mobile{
                height: 220px;
                width: 240px;
                margin: 10px;
            }

            &.featured{
                width: 400px;
                height: 75%;
                margin: 0 30px;

                @include mobile{
                    width: 280px;
                    height: 210px;
                    margin: 1px;
                }
            }

            &:hover{
                transform: scale(1.1);
            }

            .top{
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile{
                    display: none;
                }

                img{
                    &.left,&.right{
                        height: 25px;
                    }

                    &.user{
                        height: 100px;
                        width: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin: 0 30px;

                        @include mobile{
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }


            .center{
                padding: 10px;
                border-radius: 10px;
                background-color: rgb(247, 245, 246);
                
                @include mobile{
                    font-size: 9.5px;
                    padding: 3px;
                }
            }

            .bottom{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                h3{
                    margin-bottom: 5px;
                    @include mobile{
                        font-size: 12px;
                        margin-bottom: 2px;
                    }

                }

                h4{
                    color:rgb(83, 83, 83);
                    @include mobile{
                        font-size: 10px;
                    }
                }

            }
        }
   
    }
}