@import '../../global.scss';

.portfolio{
    background-color: White;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; 

    h1{
        font-size: 40px;
        overflow: hidden;
        padding-top: 25px;

        @include mobile{
            font-size: 20px;
            height: 25px;
        }
    }

    ul{
        margin: 20px;
        height: 40px;
        padding: 0;
        list-style: none;
        display: flex;
        transition: all 1s ease;
        overflow: hidden;
        font-size: var(--base-font-size);
    
        @include portfolio{
            height: 80px;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include mobile{
            display: none;
        }

    }

    h3 {
        font-size: 18px;
        height: 10%;
        align-items: center;
        text-align: center;
        margin-left: 3%;
        margin-right: 3%;

        @include mobile{
            font-size: 12px;
            margin-top: 5px;
            height: 50px;
        }
    }

    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;
        
        @include mobile{
            width: 100%;

        }

        .item{
            width: 300px;
            height: 190px;
            border-radius: 60px;
            border: 4.5px solid rgb(1, 2, 1);
            box-shadow: 5px 5px 3px #696565;
            margin: 25px 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition:  all .4s ease;
            cursor: pointer;

            @include portfolioTwo{
                width: 230px;
                height: 160px;
                margin: 30px;
            }
            
            
            @include portfolio{
                width: 200px;
                height: 130px;
            }
            

            @include mobile{
                width: 120px;
                height: 80px;
                margin: 20px;
            }

            h3{
                position: absolute;
                font-size: 20px;
                overflow: hidden;
        
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }
            

            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
}