@import '../../global.scss';


.portfoliolist{
    font-size: 16px;
    margin-right: 50px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    @include mobile{
        margin-right: 20px;
    }

    &.active{
        background-color: $mainColor;
        color: white;
    }
}